<div style="background-color: #eee1d8;">
  <!-- <div class="row" style="padding: 10px;">
    <div class="col-lg-4 col-md-4 col-sm-4">
    </div>
    <div class="col-lg-8 col-md-8 col-sm-8" style="text-align: right;">
      <button routerLink="/authentication/signin" mat-raised-button color="warn">
        Login/Register</button>
    </div>
  </div> -->
  <!-- <div class="header_menuOuter">
    <div class="container">
        <div class="header_nav">
            <div class="nav_brandBox">
                <a href="/">
                    <img src="https://www.vistaarfinance.com/images/logo.png" class="logo_img img-fluid" alt="Vistaar Finance Logo">
                </a>
            </div>
            <div class="nav_menuBar ml-auto">
                <ul class="list-unstyled">
                    <li><a href="https://customerportal.vistaarfinance.net.in:460/" target="_blank" class="emiButton btn flash">Pay Emi</a></li>
                    <li><a href="javascript:;" data-toggle="modal" data-target="#partnerWithUs">Partner with Us</a></li>
                    <li><a href="javascript:;" data-toggle="modal" data-target="#loanEnquire">Loan Enquiry</a></li>
                    <li><a href="contact">Contact Us</a></li>
                    <li><a href="tel:08030088494">080-30088494</a></li>
                </ul>
            </div>
            <div class="social_menuBox">
            <a href="https://www.facebook.com/Vistaarfinancialservices/?ref=br_rs" target="_blank"><img src="https://www.vistaarfinance.com/images/facebook.png" alt=""></a>
        <a href="https://www.linkedin.com/company/1336819" target="_blank"><img src="https://www.vistaarfinance.com/images/linkedin.png" alt=""></a>
               
            </div>
        </div>
    </div>
</div> -->


</div>